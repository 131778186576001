import axios from 'axios';
import { formatISO } from 'date-fns';
import qs from 'qs';

const headers: any = {
    'Accept': 'application/json',
    'Content': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': ' GET, PUT, POST, DELETE, OPTIONS, post, get',
    'Access-Control-Max-Age': '3600',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Credentials': 'true'
}

const httpGestor = axios.create({
    timeout: 180000,
    paramsSerializer: params => qs.stringify(params, { serializeDate: (d) => formatISO(d) }),
    baseURL: process.env.VUE_APP_API_GESTOR_URL,
    headers: headers,
});
 
export default httpGestor;